import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";
import PinView from "./components/PinView";
import Cabinet from "./components/Cabinet/Cabinet";
import Parking from "./components/Parking/Parking";
import Assign from "./components/Assign";
import CompanyPinList from "./components/CompanyPinList";
import NotFound from "./containers/NotFound";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default function Routes() {
  return (
    <Switch>
      <AuthenticatedRoute exact path="/">
        <Home />
      </AuthenticatedRoute>
      <UnauthenticatedRoute exact path="/login">
        <Login />
      </UnauthenticatedRoute>
      <AuthenticatedRoute exact path="/list">
      <CompanyPinList />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/cabinet">
      <Cabinet/>
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/parking">
      <Parking/>
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/:id">
        <PinView />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/assign/:id">
        <Assign />
      </AuthenticatedRoute>
      <Route exact path="/reload" component={null}/>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
