import React from "react";
import ReactDOM from "react-dom";
import { Amplify } from "aws-amplify";
import { HashRouter as Router } from "react-router-dom";
import "./style.css";
import App from "./App";
import config from "./config";
//import "bootstrap/dist/css/bootstrap.min.css";

//import reportWebVitals from './reportWebVitals';
import 'react-bootstrap-typeahead/css/Typeahead.css';

Amplify.configure({
  Auth: {
    //mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    cookieStorage: {
      domain: "mobile.codek.be", // EITHER LOCALHOST OR THE ACTUAL SITE YOU WANT (mobile.codek.be)
      path: "/",
      expires: 365,
      secure: false
    },
  },
  API: {
    endpoints: [
      {
        name: "pinAPI",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
