import React, { useState, useEffect } from "react";
import { Form, Alert } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { useAppContext } from "../libs/contextLib";
import LoaderButton from "./../components/LoaderButton";
import logo from "../img/logo.svg";
import { useHistory } from "react-router-dom";

//const { ipcRenderer } = window.require("electron");
import {
  resetPasswordAWS,
  confirmUserAWS,
  setCredentialsAWSLambda,
} from "../libs/lambdaLib.js";
export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [changePassword, setChangePassword] = useState(false);
  const [userChangePassword, setUserChangePassword] = useState({});
  const { userHasAuthenticated, setUsername, setCompany } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isInvalidNewPassword, setIsInvalidNewPassword] = useState(false);
  const [isSamePassword, setIsSamePassword] = useState(true);
  const history = useHistory();

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }
  function validateForgotForm() {
    return email.length > 0;
  }

  function validatePassword(pw) {
    return (
      /[A-Z]/.test(pw) &&
      /[a-z]/.test(pw) &&
      /[0-9]/.test(pw) &&
      pw.length > 8
    );
  }

  useEffect(() => {
    // ipcRenderer.send("storedUsername");
    // ipcRenderer.once("storedUsername", (event,data) => {
    //   setEmail(data);
    //   console.log("usernameRecevied" + data);
    // })
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    setIsInvalid(false);
    setIsLoading(true);

    try {
      const user = await Auth.signIn(email, password);
      console.log(user);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setChangePassword(true);
        setUserChangePassword(user);
        setIsLoading(false);
        setUserChangePassword(user);
      } else {
        setUsername(user.signInUserSession.idToken.payload["cognito:username"]);
        setCompany(user.signInUserSession.idToken.payload["custom:company"]);
        await setCredentialsAWSLambda();
        userHasAuthenticated(true);
      }
    } catch (e) {
      console.log(e);
      setIsInvalid(true);
      setIsLoading(false);
    }
  }

  async function handleChangePasswordSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
    if (!validatePassword(newPassword)) {
      setIsInvalidNewPassword(true);
      setIsLoading(false);
      return;
    }
    setIsInvalidNewPassword(false);
    if (newPassword !== confirmPassword) {
      setIsSamePassword(false);
      setIsLoading(false);
      return;
    }
    setIsSamePassword(true);
    try {
      console.log(userChangePassword);
      await Auth.completeNewPassword(userChangePassword, newPassword);
      await confirmUserAWS(email);
      setIsLoading(false);
      setChangePassword(false);
    } catch (e) {
      //alert(e.message);
      setIsLoading(false);
    }
  }

  async function onForgottenPassword(e) {
    e.preventDefault();
    console.log("resetti");
    setIsResetting(true);
    try {
      const result = await resetPasswordAWS(email);
      console.log(result);
    } catch (e) {
      console.log(e);
    } finally {
      setIsResetting(false);
    }
  }

  function renderChangePasswordForm() {
    return (
      <div class="container">
        <form
          className="login__container"
          onSubmit={handleChangePasswordSubmit}
        >
          <h1>Voer nieuw wachtwoord in</h1>
          <Form.Group
            className="form__control"
            controlId="newPassword"
            bsSize="large"
          >
            <Form.Label>Nieuw wachtwoord</Form.Label>
            <Form.Control
              type="password"
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
              isInvalid={isInvalidNewPassword}
            />
            <Form.Control.Feedback
              type="invalid"
              className="form__error"
              hidden={!isInvalidNewPassword}
            >
              Wachtwoord moet minstens 8 karakters lang zijn en een getal,
              hoofdletter en teken bevatten
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="form__control"
            controlId="confirmPassword"
            bsSize="large"
          >
            <Form.Label>Bevestig nieuw wachtwoord</Form.Label>
            <Form.Control
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              isInvalid={!isSamePassword}
            />
            <Form.Control.Feedback
              type="invalid"
              className="form__error"
              hidden={isSamePassword}
            >
              De wachtwoorden komen niet overeen
            </Form.Control.Feedback>
          </Form.Group>
          <LoaderButton isLoading={isLoading} disabled={!validateForm()}>
            Wijzig wachtwoord
          </LoaderButton>
        </form>
      </div>
    );
  }

  function renderLoginForm() {
    return (
      <div class="container">
        <Form className="login__container" onSubmit={handleSubmit}>
          <h1 className="h1">Aanmelden</h1>

          <Form.Group
            className="form__control"
            controlId="email"
            bsSize="large"
          >
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              autoFocus
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              isInvalid={isInvalid}
            />
            <Form.Control.Feedback
              type="invalid"
              hidden={!isInvalid}
              className="form__error"
            >
              Onjuist email of wachtwoord
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="form__control"
            controlId="password"
            bsSize="large"
          >
            <Form.Label>Wachtwoord</Form.Label>
            <Form.Control
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              isInvalid={isInvalid}
            />
            <Form.Control.Feedback
              type="invalid"
              hidden={!isInvalid}
              className="form__error"
            >
              Onjuist email of wachtwoord
            </Form.Control.Feedback>
          </Form.Group>
          <LoaderButton
            block
            type="submit"
            bsSize="large"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Inloggen
          </LoaderButton>
          {props.couldNotConnect ? (
            <LoaderButton
              block
              variant="danger"
              type="submit"
              bsSize="large"
              isLoading={isLoading}
            >
              Login
            </LoaderButton>
          ) : (
            <></>
          )}
          <a
            className="link"
            onClick={onForgottenPassword}
            disabled={!validateForgotForm()}
          >
            Reset wachtwoord
          </a>
        </Form>
      </div>
    );
  }

  return (
    <div className="login">
      {changePassword ? renderChangePasswordForm() : renderLoginForm()}
    </div>
  );
}
